export default [{
  date: '01.08',
  cases: 231869,
  losses: 5710,
  tests: 44846,
  totalTests: 4845669,
  positive: 996,
  dailyLoss: 19,
  recovery: 215516,
  dailyRecovery: 981
}, {
  date: '02.08',
  cases: 232856,
  losses: 5728,
  tests: 40247,
  totalTests: 4885916,
  positive: 978,
  dailyLoss: 18,
  recovery: 216494,
  dailyRecovery: 978
}, {
  date: '03.08',
  cases: 233851,
  losses: 5747,
  tests: 41301,
  totalTests: 4927217,
  positive: 995,
  dailyLoss: 19,
  recovery: 217497,
  dailyRecovery: 1003
}, {
  date: '04.08',
  cases: 234934,
  losses: 5765,
  tests: 46249,
  totalTests: 4973466,
  positive: 1083,
  dailyLoss: 18,
  recovery: 218491,
  dailyRecovery: 994
}, {
  date: '05.08',
  cases: 236112,
  losses: 5784,
  tests: 53842,
  totalTests: 5027308,
  positive: 1178,
  dailyLoss: 19,
  recovery: 219506,
  dailyRecovery: 1015
}, {
  date: '06.08',
  cases: 237265,
  losses: 5798,
  tests: 54494,
  totalTests: 5081802,
  positive: 1153,
  dailyLoss: 14,
  recovery: 220546,
  dailyRecovery: 1040
}, {
  date: '07.08',
  cases: 238450,
  losses: 5813,
  tests: 56726,
  totalTests: 5138528,
  positive: 1185,
  dailyLoss: 15,
  recovery: 221574,
  dailyRecovery: 1028
}, {
  date: '08.08',
  cases: 239622,
  losses: 5829,
  tests: 63842,
  totalTests: 5202370,
  positive: 1172,
  dailyLoss: 16,
  recovery: 222656,
  dailyRecovery: 1082
}, {
  date: '09.08',
  cases: 240804,
  losses: 5844,
  tests: 61446,
  totalTests: 5263816,
  positive: 1182,
  dailyLoss: 15,
  recovery: 223759,
  dailyRecovery: 1103
}, {
  date: '10.08',
  cases: 241997,
  losses: 5858,
  tests: 62219,
  totalTests: 5326035,
  positive: 1193,
  dailyLoss: 14,
  recovery: 224970,
  dailyRecovery: 1211
}, {
  date: '11.08',
  cases: 243180,
  losses: 5873,
  tests: 61716,
  totalTests: 5387751,
  positive: 1183,
  dailyLoss: 15,
  recovery: 226155,
  dailyRecovery: 1185
}, {
  date: '12.08',
  cases: 244392,
  losses: 5891,
  tests: 67237,
  totalTests: 5454988,
  positive: 1212,
  dailyLoss: 18,
  recovery: 227089,
  dailyRecovery: 934
}, {
  date: '13.08',
  cases: 245635,
  losses: 5912,
  tests: 66892,
  totalTests: 5521880,
  positive: 1243,
  dailyLoss: 21,
  recovery: 228057,
  dailyRecovery: 968
}, {
  date: '14.08',
  cases: 246861,
  losses: 5934,
  tests: 70192,
  totalTests: 5592072,
  positive: 1226,
  dailyLoss: 22,
  recovery: 228980,
  dailyRecovery: 923
}, {
  date: '15.08',
  cases: 248117,
  losses: 5955,
  tests: 67214,
  totalTests: 5659286,
  positive: 1256,
  dailyLoss: 21,
  recovery: 229972,
  dailyRecovery: 992
}, {
  date: '16.08',
  cases: 249309,
  losses: 5974,
  tests: 65956,
  totalTests: 5725242,
  positive: 1192,
  dailyLoss: 19,
  recovery: 230969,
  dailyRecovery: 997
}, {
  date: '17.08',
  cases: 250542,
  losses: 5996,
  tests: 74846,
  totalTests: 5800088,
  positive: 1233,
  dailyLoss: 22,
  recovery: 231971,
  dailyRecovery: 1002
}, {
  date: '18.08',
  cases: 251805,
  losses: 6016,
  tests: 82318,
  totalTests: 5882406,
  positive: 1263,
  dailyLoss: 20,
  recovery: 232913,
  dailyRecovery: 942
}, {
  date: '19.08',
  cases: 253108,
  losses: 6039,
  tests: 87223,
  totalTests: 5969629,
  positive: 1303,
  dailyLoss: 23,
  recovery: 233915,
  dailyRecovery: 1002
}, {
  date: '20.08',
  cases: 254520,
  losses: 6058,
  tests: 92301,
  totalTests: 6061930,
  positive: 1412,
  dailyLoss: 19,
  recovery: 234797,
  dailyRecovery: 882
}, {
  date: '21.08',
  cases: 255723,
  losses: 6080,
  tests: 92227,
  totalTests: 6154157,
  positive: 1203,
  dailyLoss: 22,
  recovery: 235569,
  dailyRecovery: 772
}, {
  date: '22.08',
  cases: 257032,
  losses: 6102,
  tests: 93007,
  totalTests: 6247164,
  positive: 1309,
  dailyLoss: 22,
  recovery: 235569,
  dailyRecovery: 801
}, {
  date: '23.08',
  cases: 258249,
  losses: 6121,
  tests: 80302,
  totalTests: 6327466,
  positive: 1217,
  dailyLoss: 19,
  recovery: 237165,
  dailyRecovery: 795
}, {
  date: '24.08',
  cases: 259692,
  losses: 6139,
  tests: 95943,
  totalTests: 6423409,
  positive: 1443,
  dailyLoss: 18,
  recovery: 237908,
  dailyRecovery: 743
}, {
  date: '25.08',
  cases: 261194,
  losses: 6163,
  tests: 98231,
  totalTests: 6521640,
  positive: 1502,
  dailyLoss: 24,
  recovery: 238795,
  dailyRecovery: 887
}, {
  date: '26.08',
  cases: 262507,
  losses: 6183,
  tests: 100109,
  totalTests: 6621749,
  positive: 1313,
  dailyLoss: 20,
  recovery: 239797,
  dailyRecovery: 1002
}, {
  date: '27.08',
  cases: 263998,
  losses: 6209,
  tests: 106111,
  totalTests: 6727860,
  positive: 1491,
  dailyLoss: 26,
  recovery: 240792,
  dailyRecovery: 995
}, {
  date: '28.08',
  cases: 265515,
  losses: 6245,
  tests: 107814,
  totalTests: 6835674,
  positive: 1517,
  dailyLoss: 36,
  recovery: 241809,
  dailyRecovery: 1017
}, {
  date: '29.08',
  cases: 267064,
  losses: 6284,
  tests: 101414,
  totalTests: 6937088,
  positive: 1549,
  dailyLoss: 39,
  recovery: 242812,
  dailyRecovery: 1003
}, {
  date: '30.08',
  cases: 268546,
  losses: 6326,
  tests: 91302,
  totalTests: 7028390,
  positive: 1482,
  dailyLoss: 42,
  recovery: 243839,
  dailyRecovery: 1027
}, {
  date: '31.08',
  cases: 270133,
  losses: 6370,
  tests: 110102,
  totalTests: 7138492,
  positive: 1587,
  dailyLoss: 44,
  recovery: 244926,
  dailyRecovery: 1087
}];
