export default [{
  date: '01.09',
  cases: 271705,
  losses: 6417,
  tests: 109443,
  totalTests: 7247935,
  positive: 1572,
  dailyLoss: 47,
  recovery: 245929,
  dailyRecovery: 1003
}, {
  date: '02.09',
  cases: 273301,
  losses: 6462,
  tests: 107927,
  totalTests: 7355862,
  positive: 1596,
  dailyLoss: 45,
  recovery: 246876,
  dailyRecovery: 947
}, {
  date: '03.09',
  cases: 274943,
  losses: 6511,
  tests: 110225,
  totalTests: 7466087,
  positive: 1642,
  dailyLoss: 49,
  recovery: 248087,
  dailyRecovery: 1211
}, {
  date: '04.09',
  cases: 276555,
  losses: 6564,
  tests: 117113,
  totalTests: 7583200,
  positive: 1612,
  dailyLoss: 53,
  recovery: 249108,
  dailyRecovery: 1021
}, {
  date: '05.09',
  cases: 278228,
  losses: 6620,
  tests: 99497,
  totalTests: 7682697,
  positive: 1673,
  dailyLoss: 56,
  recovery: 250092,
  dailyRecovery: 984
}, {
  date: '06.09',
  cases: 279806,
  losses: 6673,
  tests: 96842,
  totalTests: 7779539,
  positive: 1578,
  dailyLoss: 53,
  recovery: 251105,
  dailyRecovery: 1013
}, {
  date: '07.09',
  cases: 281509,
  losses: 6730,
  tests: 103925,
  totalTests: 7883464,
  positive: 1703,
  dailyLoss: 57,
  recovery: 252152,
  dailyRecovery: 1047
}, {
  date: '08.09',
  cases: 283509,
  losses: 6782,
  tests: 110565,
  totalTests: 7994029,
  positive: 1761,
  dailyLoss: 52,
  recovery: 253245,
  dailyRecovery: 1093
}, {
  date: '09.09',
  cases: 284943,
  losses: 6837,
  tests: 111193,
  totalTests: 8105222,
  positive: 1673,
  dailyLoss: 55,
  recovery: 254188,
  dailyRecovery: 943
}];
