export default [{
  date: '01.06',
  cases: 164769,
  losses: 4563,
  tests: 31525,
  totalTests: 2070719,
  positive: 827,
  dailyLoss: 23,
  recovery: 128947,
  dailyRecovery: 974
}, {
  date: '02.06',
  cases: 165555,
  losses: 4585,
  tests: 32325,
  totalTests: 2103044,
  positive: 786,
  dailyLoss: 22,
  recovery: 129921,
  dailyRecovery: 974
}, {
  date: '03.06',
  cases: 166422,
  losses: 4609,
  tests: 52305,
  totalTests: 2155359,
  positive: 867,
  dailyLoss: 24,
  recovery: 130852,
  dailyRecovery: 931
}, {
  date: '04.06',
  cases: 167410,
  losses: 4630,
  tests: 54234,
  totalTests: 2209583,
  positive: 988,
  dailyLoss: 21,
  recovery: 131778,
  dailyRecovery: 926
}, {
  date: '05.06',
  cases: 168340,
  losses: 4648,
  tests: 57829,
  totalTests: 2267412,
  positive: 930,
  dailyLoss: 18,
  recovery: 133400,
  dailyRecovery: 1622
}, {
  date: '06.06',
  cases: 169218,
  losses: 4669,
  tests: 35846,
  totalTests: 2303258,
  positive: 878,
  dailyLoss: 21,
  recovery: 135322,
  dailyRecovery: 1922
}, {
  date: '07.06',
  cases: 170132,
  losses: 4692,
  tests: 35335,
  totalTests: 2338593,
  positive: 914,
  dailyLoss: 23,
  recovery: 137969,
  dailyRecovery: 2647
}, {
  date: '08.06',
  cases: 171121,
  losses: 4711,
  tests: 39361,
  totalTests: 2377954,
  positive: 989,
  dailyLoss: 19,
  recovery: 141380,
  dailyRecovery: 3411
}, {
  date: '09.06',
  cases: 172114,
  losses: 4729,
  tests: 37225,
  totalTests: 2415179,
  positive: 993,
  dailyLoss: 18,
  recovery: 144598,
  dailyRecovery: 3218
}, {
  date: '10.06',
  cases: 173036,
  losses: 4746,
  tests: 36521,
  totalTests: 2415179,
  positive: 922,
  dailyLoss: 17,
  recovery: 146839,
  dailyRecovery: 2241
}, {
  date: '11.06',
  cases: 174023,
  losses: 4763,
  tests: 49190,
  totalTests: 2500890,
  positive: 987,
  dailyLoss: 17,
  recovery: 147860,
  dailyRecovery: 1021
}, {
  date: '12.06',
  cases: 175218,
  losses: 4778,
  tests: 41013,
  totalTests: 2541903,
  positive: 1195,
  dailyLoss: 15,
  recovery: 149102,
  dailyRecovery: 1242
}, {
  date: '13.06',
  cases: 176677,
  losses: 4792,
  tests: 45092,
  totalTests: 2586995,
  positive: 1459,
  dailyLoss: 14,
  recovery: 150087,
  dailyRecovery: 985
}, {
  date: '14.06',
  cases: 178239,
  losses: 4807,
  tests: 45176,
  totalTests: 2632171,
  positive: 1562,
  dailyLoss: 15,
  recovery: 151417,
  dailyRecovery: 1330
}, {
  date: '15.06',
  cases: 179831,
  losses: 4825,
  tests: 42032,
  totalTests: 2674203,
  positive: 1592,
  dailyLoss: 18,
  recovery: 152364,
  dailyRecovery: 947
}, {
  date: '16.06',
  cases: 181298,
  losses: 4842,
  tests: 46800,
  totalTests: 2721003,
  positive: 1467,
  dailyLoss: 17,
  recovery: 153379,
  dailyRecovery: 1015
}, {
  date: '17.06',
  cases: 182727,
  losses: 4861,
  tests: 52901,
  totalTests: 2773904,
  positive: 1429,
  dailyLoss: 19,
  recovery: 154640,
  dailyRecovery: 1261
}, {
  date: '18.06',
  cases: 184031,
  losses: 4882,
  tests: 48412,
  totalTests: 2822316,
  positive: 1304,
  dailyLoss: 21,
  recovery: 156022,
  dailyRecovery: 1382
}, {
  date: '19.06',
  cases: 185245,
  losses: 4905,
  tests: 41316,
  totalTests: 2863632,
  positive: 1214,
  dailyLoss: 23,
  recovery: 157516,
  dailyRecovery: 1494
}, {
  date: '20.06',
  cases: 186493,
  losses: 4927,
  tests: 41112,
  totalTests: 2904744,
  positive: 1248,
  dailyLoss: 22,
  recovery: 158828,
  dailyRecovery: 1312
}, {
  date: '21.06',
  cases: 187685,
  losses: 4950,
  tests: 40496,
  totalTests: 2945240,
  positive: 1192,
  dailyLoss: 23,
  recovery: 160240,
  dailyRecovery: 1412
}, {
  date: '22.06',
  cases: 188897,
  losses: 4974,
  tests: 41413,
  totalTests: 2986653,
  positive: 1212,
  dailyLoss: 24,
  recovery: 161533,
  dailyRecovery: 1293
}, {
  date: '23.06',
  cases: 190165,
  losses: 5001,
  tests: 42982,
  totalTests: 3029635,
  positive: 1268,
  dailyLoss: 27,
  recovery: 162848,
  dailyRecovery: 1315
}, {
  date: '24.06',
  cases: 191657,
  losses: 5025,
  tests: 53486,
  totalTests: 3083121,
  positive: 1492,
  dailyLoss: 24,
  recovery: 164234,
  dailyRecovery: 1386
}, {
  date: '25.06',
  cases: 193115,
  losses: 5046,
  tests: 52303,
  totalTests: 3135424,
  positive: 1458,
  dailyLoss: 21,
  recovery: 165706,
  dailyRecovery: 1472
}, {
  date: '26.06',
  cases: 194511,
  losses: 5065,
  tests: 51198,
  totalTests: 3186622,
  positive: 1396,
  dailyLoss: 19,
  recovery: 167198,
  dailyRecovery: 1492
}, {
  date: '27.06',
  cases: 195883,
  losses: 5082,
  tests: 45213,
  totalTests: 3231835,
  positive: 1372,
  dailyLoss: 17,
  recovery: 169182,
  dailyRecovery: 1984
}, {
  date: '28.06',
  cases: 197239,
  losses: 5097,
  tests: 48309,
  totalTests: 3280144,
  positive: 1356,
  dailyLoss: 15,
  recovery: 170595,
  dailyRecovery: 1413
}, {
  date: '29.06',
  cases: 198613,
  losses: 5115,
  tests: 51014,
  totalTests: 3331158,
  positive: 1374,
  dailyLoss: 18,
  recovery: 171809,
  dailyRecovery: 1214
}, {
  date: '30.06',
  cases: 199906,
  losses: 5131,
  tests: 50492,
  totalTests: 3381650,
  positive: 1293,
  dailyLoss: 16,
  recovery: 173111,
  dailyRecovery: 1302
}];
