export default [{
  date: '01.05',
  cases: 122392,
  losses: 3258,
  tests: 41431,
  totalTests: 1075048,
  positive: 2188,
  dailyLoss: 84,
  recovery: 53808,
  dailyRecovery: 4922
}, {
  date: '02.05',
  cases: 124375,
  losses: 3336,
  tests: 36318,
  totalTests: 1111366,
  positive: 1983,
  dailyLoss: 78,
  recovery: 58259,
  dailyRecovery: 4451
}, {
  date: '03.05',
  cases: 126045,
  losses: 3397,
  tests: 24001,
  totalTests: 1135367,
  positive: 1670,
  dailyLoss: 61,
  recovery: 63151,
  dailyRecovery: 4892
}, {
  date: '04.05',
  cases: 127659,
  losses: 3461,
  tests: 35771,
  totalTests: 1171138,
  positive: 1614,
  dailyLoss: 64,
  recovery: 68166,
  dailyRecovery: 5015
}, {
  date: '05.05',
  cases: 129491,
  losses: 3520,
  tests: 33283,
  totalTests: 1204421,
  positive: 1832,
  dailyLoss: 59,
  recovery: 73285,
  dailyRecovery: 5119
}, {
  date: '06.05',
  cases: 131744,
  losses: 3584,
  tests: 30303,
  totalTests: 1234724,
  positive: 2253,
  dailyLoss: 64,
  recovery: 78202,
  dailyRecovery: 4917
}, {
  date: '07.05',
  cases: 133721,
  losses: 3641,
  tests: 30395,
  totalTests: 1265119,
  positive: 1977,
  dailyLoss: 57,
  recovery: 82984,
  dailyRecovery: 4782
}, {
  date: '08.05',
  cases: 135569,
  losses: 3689,
  tests: 33687,
  totalTests: 1298806,
  positive: 1848,
  dailyLoss: 48,
  recovery: 86396,
  dailyRecovery: 3412
}, {
  date: '09.05',
  cases: 137115,
  losses: 3739,
  tests: 35605,
  totalTests: 1334411,
  positive: 1546,
  dailyLoss: 50,
  recovery: 89480,
  dailyRecovery: 3084
}, {
  date: '10.05',
  cases: 138657,
  losses: 3786,
  tests: 36187,
  totalTests: 1370598,
  positive: 1542,
  dailyLoss: 47,
  recovery: 92691,
  dailyRecovery: 3211
}, {
  date: '11.05',
  cases: 139771,
  losses: 3841,
  tests: 32722,
  totalTests: 1403320,
  positive: 1114,
  dailyLoss: 55,
  recovery: 95780,
  dailyRecovery: 3089
}, {
  date: '12.05',
  cases: 141475,
  losses: 3894,
  tests: 37351,
  totalTests: 1440671,
  positive: 1704,
  dailyLoss: 53,
  recovery: 98889,
  dailyRecovery: 3109
}, {
  date: '13.05',
  cases: 143114,
  losses: 3952,
  tests: 33332,
  totalTests: 1474003,
  positive: 1639,
  dailyLoss: 58,
  recovery: 101715,
  dailyRecovery: 2826
}, {
  date: '14.05',
  cases: 144749,
  losses: 4007,
  tests: 34821,
  totalTests: 1508824,
  positive: 1635,
  dailyLoss: 55,
  recovery: 104030,
  dailyRecovery: 2315
}, {
  date: '15.05',
  cases: 146457,
  losses: 4055,
  tests: 38565,
  totalTests: 1547389,
  positive: 1708,
  dailyLoss: 48,
  recovery: 106133,
  dailyRecovery: 2103
}, {
  date: '16.05',
  cases: 148067,
  losses: 4096,
  tests: 42236,
  totalTests: 1589625,
  positive: 1610,
  dailyLoss: 41,
  recovery: 108137,
  dailyRecovery: 2004
}, {
  date: '17.05',
  cases: 149435,
  losses: 4140,
  tests: 35369,
  totalTests: 1624994,
  positive: 1368,
  dailyLoss: 44,
  recovery: 109962,
  dailyRecovery: 1825
}, {
  date: '18.05',
  cases: 150593,
  losses: 4171,
  tests: 25141,
  totalTests: 1650135,
  positive: 1158,
  dailyLoss: 31,
  recovery: 111577,
  dailyRecovery: 1615
}, {
  date: '19.05',
  cases: 151615,
  losses: 4199,
  tests: 25382,
  totalTests: 1675517,
  positive: 1022,
  dailyLoss: 28,
  recovery: 112895,
  dailyRecovery: 1318
}, {
  date: '20.05',
  cases: 152587,
  losses: 4222,
  tests: 20838,
  totalTests: 1696355,
  positive: 972,
  dailyLoss: 23,
  recovery: 113987,
  dailyRecovery: 1092
}, {
  date: '21.05',
  cases: 153548,
  losses: 4249,
  tests: 33633,
  totalTests: 1729988,
  positive: 961,
  dailyLoss: 27,
  recovery: 114990,
  dailyRecovery: 1003
}, {
  date: '22.05',
  cases: 154500,
  losses: 4276,
  tests: 37507,
  totalTests: 1767495,
  positive: 962,
  dailyLoss: 27,
  recovery: 116111,
  dailyRecovery: 1121
}, {
  date: '23.05',
  cases: 155686,
  losses: 4308,
  tests: 40178,
  totalTests: 1807673,
  positive: 1186,
  dailyLoss: 32,
  recovery: 117602,
  dailyRecovery: 1491
}, {
  date: '24.05',
  cases: 156827,
  losses: 4340,
  tests: 24589,
  totalTests: 1832262,
  positive: 1141,
  dailyLoss: 32,
  recovery: 118694,
  dailyRecovery: 1092
}, {
  date: '25.05',
  cases: 157814,
  losses: 4369,
  tests: 21492,
  totalTests: 1853754,
  positive: 987,
  dailyLoss: 29,
  recovery: 120015,
  dailyRecovery: 1321
}, {
  date: '26.05',
  cases: 158762,
  losses: 4397,
  tests: 19853,
  totalTests: 1873607,
  positive: 948,
  dailyLoss: 28,
  recovery: 121507,
  dailyRecovery: 1492
}, {
  date: '27.05',
  cases: 159797,
  losses: 4431,
  tests: 21043,
  totalTests: 1894650,
  positive: 1035,
  dailyLoss: 34,
  recovery: 122793,
  dailyRecovery: 1286
}, {
  date: '28.05',
  cases: 160979,
  losses: 4461,
  tests: 33559,
  totalTests: 1928209,
  positive: 1182,
  dailyLoss: 30,
  recovery: 124369,
  dailyRecovery: 1576
}, {
  date: '29.05',
  cases: 162120,
  losses: 4489,
  tests: 36155,
  totalTests: 1964364,
  positive: 1141,
  dailyLoss: 28,
  recovery: 125963,
  dailyRecovery: 1594
}, {
  date: '30.05',
  cases: 163103,
  losses: 4515,
  tests: 39230,
  totalTests: 2003594,
  positive: 983,
  dailyLoss: 26,
  recovery: 126984,
  dailyRecovery: 1021
}, {
  date: '31.05',
  cases: 163942,
  losses: 4540,
  tests: 35600,
  totalTests: 2039194,
  positive: 839,
  dailyLoss: 25,
  recovery: 127973,
  dailyRecovery: 989
}];
