export default [{
  date: '01.07',
  cases: 201098,
  losses: 5150,
  tests: 52313,
  totalTests: 3433963,
  positive: 1192,
  dailyLoss: 19,
  recovery: 175422,
  dailyRecovery: 2311
}, {
  date: '02.07',
  cases: 202284,
  losses: 5167,
  tests: 49714,
  totalTests: 3483677,
  positive: 1186,
  dailyLoss: 17,
  recovery: 176965,
  dailyRecovery: 1543
}, {
  date: '03.07',
  cases: 203456,
  losses: 5186,
  tests: 52141,
  totalTests: 3535818,
  positive: 1172,
  dailyLoss: 19,
  recovery: 178278,
  dailyRecovery: 1313
}, {
  date: '04.07',
  cases: 204610,
  losses: 5206,
  tests: 48248,
  totalTests: 3584066,
  positive: 1154,
  dailyLoss: 20,
  recovery: 179492,
  dailyRecovery: 1214
}, {
  date: '05.07',
  cases: 205758,
  losses: 5225,
  tests: 46414,
  totalTests: 3630480,
  positive: 1148,
  dailyLoss: 19,
  recovery: 180680,
  dailyRecovery: 1188
}, {
  date: '06.07',
  cases: 206844,
  losses: 5241,
  tests: 52193,
  totalTests: 3682673,
  positive: 1086,
  dailyLoss: 16,
  recovery: 182995,
  dailyRecovery: 2315
}, {
  date: '07.07',
  cases: 207897,
  losses: 5260,
  tests: 50545,
  totalTests: 3733218,
  positive: 1053,
  dailyLoss: 19,
  recovery: 185292,
  dailyRecovery: 2297
}, {
  date: '08.07',
  cases: 208938,
  losses: 5282,
  tests: 49302,
  totalTests: 3782520,
  positive: 1041,
  dailyLoss: 22,
  recovery: 187511,
  dailyRecovery: 2219
}, {
  date: '09.07',
  cases: 209962,
  losses: 5300,
  tests: 50103,
  totalTests: 3832623,
  positive: 1024,
  dailyLoss: 18,
  recovery: 190390,
  dailyRecovery: 2879
}, {
  date: '10.07',
  cases: 210965,
  losses: 5323,
  tests: 48787,
  totalTests: 3881410,
  positive: 1003,
  dailyLoss: 23,
  recovery: 191883,
  dailyRecovery: 1493
}, {
  date: '11.07',
  cases: 211981,
  losses: 5344,
  tests: 48813,
  totalTests: 3930223,
  positive: 1016,
  dailyLoss: 21,
  recovery: 193217,
  dailyRecovery: 1334
}, {
  date: '12.07',
  cases: 212993,
  losses: 5363,
  tests: 45232,
  totalTests: 3975455,
  positive: 1012,
  dailyLoss: 19,
  recovery: 194515,
  dailyRecovery: 1298
}, {
  date: '13.07',
  cases: 214001,
  losses: 5382,
  tests: 46492,
  totalTests: 4021947,
  positive: 1008,
  dailyLoss: 19,
  recovery: 195671,
  dailyRecovery: 1156
}, {
  date: '14.07',
  cases: 214993,
  losses: 5402,
  tests: 43231,
  totalTests: 4065178,
  positive: 992,
  dailyLoss: 20,
  recovery: 196720,
  dailyRecovery: 1049
}, {
  date: '15.07',
  cases: 215940,
  losses: 5419,
  tests: 42320,
  totalTests: 4107498,
  positive: 947,
  dailyLoss: 17,
  recovery: 197733,
  dailyRecovery: 1013
}, {
  date: '16.07',
  cases: 216873,
  losses: 5440,
  tests: 42411,
  totalTests: 4149909,
  positive: 933,
  dailyLoss: 21,
  recovery: 198820,
  dailyRecovery: 1087
}, {
  date: '17.07',
  cases: 217799,
  losses: 5458,
  tests: 41215,
  totalTests: 4191124,
  positive: 926,
  dailyLoss: 18,
  recovery: 199834,
  dailyRecovery: 1014
}, {
  date: '18.07',
  cases: 218717,
  losses: 5475,
  tests: 40943,
  totalTests: 4232067,
  positive: 918,
  dailyLoss: 17,
  recovery: 201013,
  dailyRecovery: 1179
}, {
  date: '19.07',
  cases: 219641,
  losses: 5491,
  tests: 41310,
  totalTests: 4273377,
  positive: 924,
  dailyLoss: 16,
  recovery: 202010,
  dailyRecovery: 997
}, {
  date: '20.07',
  cases: 220572,
  losses: 5508,
  tests: 43404,
  totalTests: 4316781,
  positive: 931,
  dailyLoss: 17,
  recovery: 203002,
  dailyRecovery: 992
}, {
  date: '21.07',
  cases: 221500,
  losses: 5526,
  tests: 42846,
  totalTests: 4359627,
  positive: 928,
  dailyLoss: 18,
  recovery: 204011,
  dailyRecovery: 1009
}, {
  date: '22.07',
  cases: 222402,
  losses: 5545,
  tests: 43404,
  totalTests: 4403031,
  positive: 902,
  dailyLoss: 19,
  recovery: 205214,
  dailyRecovery: 1203
}, {
  date: '23.07',
  cases: 223315,
  losses: 5563,
  tests: 43343,
  totalTests: 4446374,
  positive: 913,
  dailyLoss: 18,
  recovery: 206365,
  dailyRecovery: 1151
}, {
  date: '24.07',
  cases: 224252,
  losses: 5580,
  tests: 42986,
  totalTests: 4489360,
  positive: 937,
  dailyLoss: 17,
  recovery: 207374,
  dailyRecovery: 1009
}, {
  date: '25.07',
  cases: 225173,
  losses: 5596,
  tests: 43312,
  totalTests: 4532672,
  positive: 921,
  dailyLoss: 16,
  recovery: 208477,
  dailyRecovery: 1103
}, {
  date: '26.07',
  cases: 226100,
  losses: 5613,
  tests: 40016,
  totalTests: 4572688,
  positive: 927,
  dailyLoss: 17,
  recovery: 209487,
  dailyRecovery: 1010
}, {
  date: '27.07',
  cases: 227019,
  losses: 5630,
  tests: 45283,
  totalTests: 4617971,
  positive: 919,
  dailyLoss: 17,
  recovery: 210469,
  dailyRecovery: 982
}, {
  date: '28.07',
  cases: 227982,
  losses: 5645,
  tests: 47412,
  totalTests: 4665383,
  positive: 963,
  dailyLoss: 15,
  recovery: 211561,
  dailyRecovery: 1092
}, {
  date: '29.07',
  cases: 228924,
  losses: 5659,
  tests: 45712,
  totalTests: 4711095,
  positive: 942,
  dailyLoss: 14,
  recovery: 212557,
  dailyRecovery: 996
}, {
  date: '30.07',
  cases: 229891,
  losses: 5674,
  tests: 43236,
  totalTests: 4754331,
  positive: 967,
  dailyLoss: 15,
  recovery: 213539,
  dailyRecovery: 982
}, {
  date: '31.07',
  cases: 230873,
  losses: 5691,
  tests: 46492,
  totalTests: 4800823,
  positive: 982,
  dailyLoss: 17,
  recovery: 214535,
  dailyRecovery: 996
}];
